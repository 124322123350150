/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://rsms.me/inter/inter.css');
html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
} */

@font-face {
  font-family: 'PlusJakartaSans';
  src: url('./css/fonts/PlusJakartaSans-VariableFont_wght.ttf') format("truetype-variations");
  font-weight: 1 999;
}

@layer base {
  html {
    font-family: 'PlusJakartaSans', system-ui, sans-serif;
  }
}

body {
  margin: 0;
  font-family: 'PlusJakartaSans' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
