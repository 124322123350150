 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Georgia;
	panose-1:2 4 5 2 5 4 5 2 3 3;}
@font-face
	{font-family:Times;
	panose-1:2 2 6 3 5 4 5 2 3 4;}
@font-face
	{font-family:m;}
@font-face
	{font-family:Tahoma;
	panose-1:2 11 6 4 3 5 4 4 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0in;
	text-align:left;
	font-size:10.0pt;
	font-family:"Times New Roman",serif;}
.MsoChpDefault
	{font-size:10.0pt;}
 /* Page Definitions */
 @page WordSection1
	{size:8.5in 11.0in;
	margin:1.0in 1.0in 1.0in 1.0in;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol.word
	{margin-bottom:0in;}
ul.word
	{margin-bottom:0in;}

.ui.top.aligned.page.modals.dimmer {
	overflow: scroll;
}

.PhoneInputInput {
	font-size: 0.875rem;
	line-height: 1.25rem;
	--tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
	--tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
	--tw-border-opacity: 1;
	border-color: rgb(209 213 219 / var(--tw-border-opacity));	
	border-radius: 0.375rem;
	width: 100%;
	display: block;
}

.PhoneInputInput:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(222 188 124 / var(--tw-ring-opacity));
}
.PhoneInputInput:focus {
  --tw-border-opacity: 1;
  border-color: rgb(222 188 124 / var(--tw-border-opacity));
}
.PhoneInputInput:disabled {
	--tw-text-opacity: 1;
	color: rgb(156 163 175 / var(--tw-text-opacity));
}
.required:after {
  content:" *";
  color: rgb(220 38 38);
}